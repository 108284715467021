import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { IUser, IUserLocation } from "app/store/types/user.types";
import { AxiosServiceError } from "app/utils/api/axios/service_error";
import i18n from "i18n";
import API from "app/utils/api/axios";
import API_URL from "app/constants/api_urls";
import Storage from "app/utils/storage/local";
import STORAGE_CONSTANTS from "app/constants/storage";

export enum SOCIAL_PROVIDERS {
    GOOGLE = "google",
    FACEBOOK = "facebook",
}

export type Location = "United Kingdom" | "United States" | "Canada" | "Australia";

export type UpdateUserType = {
    location: {
        name: Location;
    };
    username: string;
    first_name: string;
    last_name: string;
    image: { base64: string | ArrayBuffer };
    prefers_reduced_motion: boolean;
};

interface IUserSlice {
    isLoading: boolean;
    isFacebookLoading: boolean;
    isGoogleLoading: boolean;
    isAuthenticated: boolean;
    user: Partial<IUser>;
    userLocations: Partial<IUserLocation[]> | undefined;
    flushUser: () => void;
    reAuthenticate: () => void;
    getUser: () => Promise<IUser>;
    updateUser: (pk: number, arg: Partial<UpdateUserType>) => Promise<IUser>;
    getUserLocations: () => Promise<IUserLocation[]>;
    logoutUser: () => Promise<{ data: { detail: string }; status: number }>;
    socialLogin: (
        token: string,
        provider: "google" | "facebook"
    ) => Promise<{ [key: string]: string }>;
    loginWithEmailAndPassword: (
        email: string,
        password: string
    ) => Promise<{ data: { key: string } }>;
    registerUser: (
        username: string,
        email: string,
        password: string,
        location: string
    ) => Promise<{ data: { key: string } }>;
}

const initialState = {
    user: {},
    userLocations: undefined,
    isLoading: false,
    isFacebookLoading: false,
    isGoogleLoading: false,
    isAuthenticated: false,
};

const userSlice = create<IUserSlice>()(
    devtools(
        (set, get) => ({
            ...initialState,
            flushUser: () => {
                set({ ...initialState }, false, "userSlice/flushUser");
            },
            reAuthenticate: () => {
                set(
                    {
                        isAuthenticated: true,
                        isLoading: true,
                    },
                    false,
                    "userSlice/reAuthenticate"
                );
            },
            getUser: async (): Promise<IUser> => {
                set({ isLoading: true }, false, "userSlice/getUser");
                const { data } = await API.get(API_URL.USER_DATA);
                const apps = await API.get(API_URL.USER_APPS);
                const prepareApps = apps?.data?.map(
                    (app: { display_name: string; slug: string }) => ({
                        ...app,
                        slug: app.slug.replace(/-/g, "_"),
                    })
                );
                await get().getUserLocations();
                set(
                    { user: { ...data, apps: prepareApps }, isLoading: false },
                    false,
                    "userSlice/getUser"
                );
                return data;
            },
            updateUser: async (pk: number, payload: Partial<UpdateUserType>): Promise<IUser> => {
                try {
                    const { apps } = get().user as IUser;
                    const { data } = await API.patch(
                        `${API_URL.UPDATE_USER_PROFILE}${pk}/update/`,
                        payload
                    );
                    i18n.changeLanguage(data.location.language);
                    Storage.set(STORAGE_CONSTANTS.userLocation, data.location.language);
                    set({ user: { ...data, apps } }, false, "userSlice/updateUser");
                    return data;
                } catch (err) {
                    throw new AxiosServiceError(err);
                }
            },
            getUserLocations: async (): Promise<IUserLocation[]> => {
                const { data } = await API.get(API_URL.USER_LOCATIONS);
                set({ userLocations: data }, false, "userSlice/userLocations");
                return data;
            },
            loginWithEmailAndPassword: async (
                email: string,
                password: string
            ): Promise<{ data: { key: string } }> => {
                try {
                    set({ isLoading: true }, false, "userSlice/loginWithEmailAndPassword");
                    const { data } = await API.post(API_URL.LOGIN_USER, {
                        email,
                        password,
                    });

                    Storage.set(STORAGE_CONSTANTS.accessToken, data.key);
                    const [user] = await Promise.all([get().getUser(), get().getUserLocations]);

                    i18n.changeLanguage(user.location.language);
                    Storage.set(STORAGE_CONSTANTS.userLocation, user.location.language);
                    if (user.location_confirmed)
                        Storage.set(
                            STORAGE_CONSTANTS.loginSuccessMessage,
                            `Welcome ${user.greeting_name}`
                        );

                    set({ isAuthenticated: true }, false, "userSlice/loginWithEmailAndPassword");
                    return data;
                } catch (err) {
                    set({ isLoading: false }, false, "userSlice/loginWithEmailAndPassword");
                    throw new AxiosServiceError(err);
                }
            },
            registerUser: async (
                username: string,
                email: string,
                password: string,
                location: string
            ): Promise<{ data: { key: string } }> => {
                try {
                    const preparePayload = {
                        username,
                        email,
                        password1: password,
                        password2: password,
                        location: {
                            name: location,
                        },
                    };
                    const { data } = await API.post(API_URL.REGISTER_USER, preparePayload);
                    Storage.set(STORAGE_CONSTANTS.accessToken, data.key);

                    const [user] = await Promise.all([get().getUser(), get().getUserLocations]);
                    i18n.changeLanguage(user.location.language);
                    Storage.set(STORAGE_CONSTANTS.userLocation, user.location.language);
                    Storage.set(
                        STORAGE_CONSTANTS.loginSuccessMessage,
                        `Welcome ${user.greeting_name}`
                    );

                    set({ isAuthenticated: true }, false, "userSlice/registerUser");
                    return data;
                } catch (err) {
                    throw new AxiosServiceError(err);
                }
            },
            socialLogin: async (
                token: string,
                provider: "google" | "facebook"
            ): Promise<{ [key: string]: string }> => {
                try {
                    const data: Partial<{ key: string }> = {};
                    if (provider === SOCIAL_PROVIDERS.FACEBOOK) {
                        const response = await API.post(API_URL.LOGIN_FACEBOOK, {
                            access_token: token,
                        });
                        data.key = response.data.key;
                    }
                    if (provider === SOCIAL_PROVIDERS.GOOGLE) {
                        const response = await API.post(API_URL.LOGIN_GOOGLE, {
                            access_token: token,
                        });
                        data.key = response.data.key;
                    }
                    Storage.set(STORAGE_CONSTANTS.accessToken, data?.key as string);
                    const [user] = await Promise.all([get().getUser(), get().getUserLocations]);
                    Storage.set(STORAGE_CONSTANTS.userLocation, user.location.language);

                    if (user.location_confirmed)
                        Storage.set(
                            STORAGE_CONSTANTS.loginSuccessMessage,
                            `Welcome ${user.greeting_name}`
                        );

                    set({ isAuthenticated: true }, false, "userSlice/socialLogin");
                    return data;
                } catch (err) {
                    throw new AxiosServiceError(err);
                }
            },
            logoutUser: async (): Promise<{ data: { detail: string }; status: number }> => {
                try {
                    const { data, status } = await API.get(API_URL.LOGOUT_USER);
                    if (status === 200) Storage.remove("access_token");
                    set({ ...initialState }, false, "userSlice/loginUser");

                    return {
                        data,
                        status,
                    };
                } catch (err) {
                    throw new AxiosServiceError(err);
                }
            },
        }),
        { name: "User slice", enabled: !import.meta.env.PROD }
    )
);

export default userSlice;
