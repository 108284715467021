import STORAGE_CONSTANTS from "app/constants/storage";
import userSlice from "app/store/user/user.slice";
import Storage from "app/utils/storage/local";
import { useState, useEffect } from "react";

export const usePrefersReducedMotion = () => {
    const [prefersReducedMotion, setPrefersReducedMotion] = useState(
        () => window.matchMedia("(prefers-reduced-motion: reduce)").matches
    );

    useEffect(() => {
        const mediaQuery = window.matchMedia("(prefers-reduced-motion: reduce)");

        const handleChange = (event: MediaQueryListEvent) => {
            setPrefersReducedMotion(event.matches);
        };

        mediaQuery.addEventListener("change", handleChange);
        return () => mediaQuery.removeEventListener("change", handleChange);
    }, []);

    return prefersReducedMotion;
};

export const useUpdateUserPrefersReducedMotion = () => {
    const { user, updateUser } = userSlice((state) => state);

    const prefersReducedMotion = usePrefersReducedMotion();
    const prefersReducedMotionReported = Storage.get(
        STORAGE_CONSTANTS.prefersReducedMotionReported
    );

    // Update user's prefers_reduced_motion
    useEffect(() => {
        if (user.pk && !prefersReducedMotionReported) {
            updateUser(user.pk, {
                prefers_reduced_motion: prefersReducedMotion,
            });

            Storage.set(STORAGE_CONSTANTS.prefersReducedMotionReported, "true");
        }
    }, [prefersReducedMotion, updateUser, user.pk, prefersReducedMotionReported]);
};
