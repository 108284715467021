const STORAGE_CONSTANTS = {
    paymentSuccessMessage: "paymentSuccessMessage",
    paymentErrorMessage: "paymentErrorMessage",
    loginSuccessMessage: "loginSuccessMessage",
    loginErrorMessage: "loginErrorMessage",
    deleteRaceSuccess: "deleteRaceSuccess",
    currentRace: "currentRace",
    importRaceModalVisible: "importRaceModalVisible",
    paymentRedirect: "redirectAfterPayment",
    editRaceSuccess: "editRaceSuccess",
    addRaceSuccess: "addRaceSuccess",
    userLocation: "userLocation",
    addRaceRedirect: "addRaceRedirect",
    editRaceRedirect: "editRaceRedirect",
    passwordResetMessage: "passwordResetMessage",
    passwordResetSuccessMessage: "passwordResetSuccessMessage",
    postOrganizationValues: "postOrganizationValues",
    accessToken: "access_token",
    currentListing: "currentListing",
    isNavbarToggled: "isNavbarToggled",
    bbTrialDontAskAgain: "bbTrialDontAskAgain",
    bbTrialEnded: "bbTrialEnded",
    listingPaymentRedirect: "listingPaymentRedirect",
    currentDashboard: "currentDashboard",
    budgetEventInfoTableAlert: "budgetEventInfoTableAlert",
    prefersReducedMotionReported: "prefersReducedMotionValueReported",
};

type StorageKeys = keyof typeof STORAGE_CONSTANTS;
export type StorageConstantsType = (typeof STORAGE_CONSTANTS)[StorageKeys];

export default STORAGE_CONSTANTS;
